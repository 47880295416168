<template>
  <BodySimple>
    <template #header>
      <h2 class="text-lg font-bold">
        <img src="@/assets/images/ai-robot.png" class="h-20 inline-block" />
        ربات ها
      </h2>
    </template>
    <template #default>
      <div class="p-3 bg-blueGray-100 border rounded-md border-blueGray-200 mb-5">
        <form action="" @submit.prevent="submit">
          <MnrSelect
            class="mb-4"
            v-model="inputs.botType"
            dValue="all"
            dText="همه رباتها"
            :options="
              L_.uniqBy(
                L_.map(bots, function (bot) {
                  return { text: bot.type, value: bot.type };
                }),
                'value'
              )
            "
            placeholder="همه ربات ها"
          />
          <MnrSelect
            v-model="inputs.method"
            :options="options.methods"
            placeholder="انتخاب عملیات"
          />
          <div class="flex flex-wrap sm:flex-nowrap my-3" v-if="inputs.method != 'off'">
            <MnrCheckSlider
              v-model:checked="inputs.changeServer"
              class="w-full sm:w-4/12"
            >
              تغییر سرور
            </MnrCheckSlider>
            <MnrText
              v-show="inputs.changeServer"
              v-model="inputs.server"
              placeholder="server"
              dir="ltr"
              class="w-full sm:w-8/12"
            />
          </div>
          <div class="mt-10" v-if="inputs.method == 'setWebhook'">
            <MnrText
              v-model="inputs.setWebhook.url"
              placeholder="url"
              dir="ltr"
              class="mb-3"
            />
            <MnrText
              v-model="inputs.setWebhook.ip"
              placeholder="ip_address"
              dir="ltr"
              class="mb-3"
            />
            <MnrNumber
              v-model="inputs.setWebhook.max"
              placeholder="max_connections"
              dir="ltr"
              class="mb-3"
            />
            <MnrCheckSlider v-model:checked="inputs.setWebhook.drop_pending_updates">
              drop_pending_updates
            </MnrCheckSlider>
          </div>
          <div class="mt-10" v-if="inputs.method == 'deleteWebhook'">
            <MnrCheckSlider v-model:checked="inputs.setWebhook.drop_pending_updates">
              drop_pending_updates
            </MnrCheckSlider>
          </div>
          <div class="mt-10" v-if="inputs.method == 'getUpdates'"></div>
          <div
            class="flex flex-wrap gap-x-3"
            dir="ltr"
            v-if="inputs.method == 'getUpdates' || inputs.method == 'setWebhook'"
          >
            <hr class="my-3 border-blueGray-200 w-full" />
            <MnrCheckSlider v-model:list="inputs.allowed_updates" value="message">
              message
            </MnrCheckSlider>
            <MnrCheckSlider v-model:list="inputs.allowed_updates" value="edited_message">
              edited_message
            </MnrCheckSlider>
            <MnrCheckSlider v-model:list="inputs.allowed_updates" value="channel_post">
              channel_post
            </MnrCheckSlider>
            <MnrCheckSlider
              v-model:list="inputs.allowed_updates"
              value="edited_channel_post"
            >
              edited_channel_post
            </MnrCheckSlider>
            <MnrCheckSlider v-model:list="inputs.allowed_updates" value="inline_query">
              inline_query
            </MnrCheckSlider>
            <MnrCheckSlider
              v-model:list="inputs.allowed_updates"
              value="chosen_inline_result"
            >
              chosen_inline_result
            </MnrCheckSlider>
            <MnrCheckSlider v-model:list="inputs.allowed_updates" value="callback_query">
              callback_query
            </MnrCheckSlider>
            <MnrCheckSlider v-model:list="inputs.allowed_updates" value="shipping_query">
              shipping_query
            </MnrCheckSlider>
            <MnrCheckSlider
              v-model:list="inputs.allowed_updates"
              value="pre_checkout_query"
            >
              pre_checkout_query
            </MnrCheckSlider>
            <MnrCheckSlider v-model:list="inputs.allowed_updates" value="poll">
              poll
            </MnrCheckSlider>
            <MnrCheckSlider v-model:list="inputs.allowed_updates" value="poll_answer">
              poll_answer
            </MnrCheckSlider>
            <MnrCheckSlider v-model:list="inputs.allowed_updates" value="my_chat_member">
              my_chat_member
            </MnrCheckSlider>
            <MnrCheckSlider v-model:list="inputs.allowed_updates" value="chat_member">
              chat_member
            </MnrCheckSlider>
            <MnrCheckSlider
              v-model:list="inputs.allowed_updates"
              value="chat_join_request"
            >
              chat_join_request
            </MnrCheckSlider>
          </div>
          <Button type="submit" class="mt-5" v-if="inputs.method != 'off'">اعمال</Button>
        </form>
      </div>
      <div class="grid sm:grid-cols-3 xl:grid-cols-4 gap-3">
        <div
          v-for="bot in bots"
          :key="bot.id"
          class="bg-blueGray-100 border border-blueGray-200 rounded-md"
        >
          <div class="p-2 bg-blueGray-200 rounded-t-md flex items-center">
            <div class="w-full">{{ bot.name }}</div>
            <div class="w-full text-left text-blueGray-600 text-sm" dir="ltr">
              @{{ bot.username }}
            </div>
          </div>
          <div class="p-2 rounded-t-md flex items-center">
            <div class="w-full">
              {{
                L_.find(info, ["bot.id", bot.id])?.result?.pending_update_count ?? "..."
              }}
            </div>
            <div class="w-full text-left" dir="ltr">pending</div>
          </div>
          <div class="p-2 items-center">
            <div
              class="w-full text-center"
              v-if="!L_.find(info, ['bot.id', bot.id])?.result"
            >
              دریافت نشده
            </div>
            <div
              class="w-full text-center text-green-500 font-bold"
              v-else-if="L_.find(info, ['bot.id', bot.id])?.result?.url"
            >
              در حال کار
            </div>
            <div class="w-full text-center text-red-500 font-bold" v-else>بدون وبهوک</div>
          </div>
          <div class="p-1">
            <Button
              :to="{ name: 'AdminBot', params: { botId: bot.id } }"
              class="text-sm h-10 lg:text-lg"
              color="sky"
            >
              بیشتر
            </Button>
          </div>
        </div>
      </div>

      <div
        v-show="info.length"
        class="
          p-3
          bg-coolGray-800
          text-coolGray-100
          rounded-md
          mt-4
          max-h-screen
          overflow-auto
        "
      >
        <template v-for="bot in info" :key="bot.bot.id">
          <pre class="" dir="ltr">{{ pretty(bot) }}</pre>
          <hr class="my-3 border-coolGray-600" />
        </template>
      </div>
      <div
        v-show="res.length"
        class="
          p-3
          bg-coolGray-800
          text-coolGray-100
          rounded-md
          mt-4
          max-h-screen
          overflow-auto
        "
      >
        <template v-for="bot in res" :key="bot.bot.id">
          <pre class="" dir="ltr">{{ pretty(bot) }}</pre>
          <hr class="my-3 border-coolGray-600" />
        </template>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Button from "@/components/Button.vue";
import _ from "lodash";
import MnrSelect from "@/components/mnr/MnrSelect.vue";
import MnrCheckSlider from "@/components/mnr/MnrCheckSlider.vue";
import MnrText from "@/components/mnr/MnrText.vue";
import MnrNumber from "@/components/mnr/MnrNumber.vue";

export default {
  components: { BodySimple, Button, MnrSelect, MnrCheckSlider, MnrText, MnrNumber },
  data() {
    return {
      firstLoading: true,
      bots: [],
      info: [],
      res: [],
      L_: _,

      inputs: {
        botType: "all",
        method: "getWebhookInfo",
        changeServer: false,
        server: "",
        allowed_updates: [],
        setWebhook: {
          url: "",
          ip: "",
          max: null,

          drop_pending_updates: false,
        },
        deleteWebhook: {
          drop_pending_updates: false,
        },
        getUpdates: {},
      },
      options: {
        methods: [
          {
            text: "setWebhook",
            value: "setWebhook",
          },
          {
            text: "deleteWebhook",
            value: "deleteWebhook",
          },
          {
            text: "getUpdates",
            value: "getUpdates",
          },
          {
            text: "getWebhookInfo",
            value: "getWebhookInfo",
          },
          {
            text: "close",
            value: "close",
          },
          {
            text: "setMyCommands",
            value: "setMyCommands",
          },
          {
            text: "getMyCommands",
            value: "getMyCommands",
          },
          {
            text: "deleteMyCommands",
            value: "deleteMyCommands",
          },
        ],
      },
    };
  },
  // computed: {
  //   res() {
  //     return this.info;
  //   },
  // },
  mounted() {
    this.getData();
  },
  methods: {
    pretty(value) {
      return JSON.stringify(value, null, 2);
    },
    getData() {
      let $this = this;
      // console.log($this.$store.state.user.id);
      $this.$axios
        .get("/api/admin/bots")
        .then(function (response) {
          // console.log(response);
          $this.bots = response.data.bots;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .finally(function () {
          // always executed
          $this.firstLoading = false;
        });
    },
    submit() {
      this.info = [];
      this.res = [];
      let $this = this;
      if ($this.inputs.botType == "all") {
        $this.bots.forEach(function (bot) {
          $this.submitAll(bot);
        });
      } else {
        // console.log(_.filter($this.bots, ["type", $this.inputs.botType]));
        _.filter($this.bots, ["type", $this.inputs.botType]).forEach(function (bot) {
          $this.submitAll(bot);
        });
      }
    },
    submitAll: async function (bot) {
      try {
        const response = await this.$axios.post("/api/admin/bots-webhook/" + bot.id, {
          inputs: this.inputs,
        });
        // console.log(response);
        if (this.inputs.method == "getWebhookInfo") {
          let info = response.data;
          info.bot = bot;
          this.info.push(info);
        } else {
          let info = response.data;
          info.bot = bot;
          this.res.push(info);
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
